<script lang="js">
const { APP_DEBUG } = useRuntimeConfig().public;

export default {
  props: {
    eventName: {
      type: String,
      default: "edit",
    },
    addr: {
      type: Object,
      default: () => {
        return {
          address_id: "",
          address_label: "",
          name: "",
          email: "",
          phone: "",
          status: "",
          type: "",
          address_1: "",
          address_2: "",
          address_3: "",
          address_4: "",
          city: "",
          state_province: "",
          country_code: "",
          postal_code: "",
          address_note: "",
          lat: 0,
          lng: 0,
        };
      },
    },
  },
  setup() {
    const { isIos, isMacOS } = useDevice();
    const shopper = useShopperStore();
    const center = ref({ lat: -6.3020054, lng: 106.832854 });
    const markers = ref([
      {
        description: "FLIK",
        id: "1",
        position: { lat: -6.3020054, lng: 106.832854 },
      },
    ]);

    const gmapRef = ref(false);
    const markerdrag = ref(false);
    const currentPlace = ref(null);
    const postalCodes = ref([]);

    const myPhone = shopper.myPhone;
    const myEmail = shopper.myEmail;
    const firstName = shopper.firstName;
    const lastName = shopper.lastName;
    console.log("myPhone:", myPhone);

    const errorMessage = ref("");

    // read cookie from shopper app
    const shopLat = useCookie("shopper.lat");
    const shopLong = useCookie("shopper.long");

    return {
      shopLat,
      shopLong,
      firstName,
      lastName,
      myPhone,
      myEmail,
      errorMessage,
      postalCodes,
      gmapRef,
      markerdrag,
      currentPlace,
      isIos,
      isMacOS,
      shopper,
      center,
      markers,
    };
  },
  data() {
    return {
      shipme: true,
      postal_code: 0,
      r_name: "",
      r_phone: "",
      addr_label: "",
      c_address_1: "",
      c_address_2: "",
      cs_province: null,
      cs_regency: null,
      cs_district: null,
      cs_village: null,
      cs_postalcode: null,
      geocodeLoading: false,
      gprovince: this.addr.state_province,
      gcity: this.addr.city,
      gpostalcode: this.addr.postal_code,
      gdistrict: "",
      gvillage: "",
      formattedAddr: "",
      addrAssigned: false,
      postalData: [],
      showModalPostal: false,
      selectedPostalCode: null,
      placeKeyword: "",
      loadingPostal: false,
      logGetPermission: "",
      APP_DEBUG: APP_DEBUG === "on",
    };
  },
  computed: {
    recipient_name: {
      get() {
        let strName = " ";
        if (this.addr.address_label.length > 0 && this.r_name.length === 0) {
          const splitAddr = this.addr.address_label.split("|");
          strName = splitAddr[0];
        } else {
          strName = this.r_name;
        }
        return strName.trim();
      },
      // setter
      set(newVal) {
        this.r_name = newVal.length === 0 ? " " : newVal;
      },
    },
    recipient_phone: {
      get() {
        let strPhone = "";
        if (this.addr.address_label.length > 0 && this.r_phone.length === 0) {
          const splitAddr = this.addr.address_label.split("|");
          strPhone = splitAddr[1];
        } else {
          strPhone = this.r_phone;
        }
        return strPhone.trim();
      },
      // setter
      set(newVal) {
        this.r_phone = newVal.length === 0 ? " " : newVal;
      },
    },
    address_1: {
      // getter
      get() {
        let str = this.c_address_1;
        if (this.addr.address_1.length > 0 && this.c_address_1.length === 0) {
          str = this.addr.address_1;
        }
        return str;
      },
      // setter
      set(newVal) {
        this.c_address_1 = newVal.length === 0 ? " " : newVal;
      },
    },
    address_2: {
      // getter
      get() {
        let str = this.c_address_2;
        if (this.addr.address_2.length > 0 && this.c_address_2.length === 0) {
          str = this.addr.address_2;
        }
        return str;
      },
      // setter
      set(newVal) {
        this.c_address_2 = newVal.length === 0 ? " " : newVal;
      },
    },
  },
  mounted() {
    if (!this.shopper.flags.is_guest) {
      this.shipme = true;
    } else {
      this.shipme = false;
    }
    if (this.eventName === "add") {
      amplitudeTrackPageView("Checkout Add Address Page");
    }

    if (this.addr.lat && this.addr.lng) {
      this.markerdrag = true;
      console.log("this.addr:", this.addr);
      this.$nextTick(() => {
        const marker = {
          lat: this.addr.lat,
          lng: this.addr.lng,
        };
        // set lat lng as new marker & place
        this.setMarkerPosition(marker);
      });
    }

    // watchEffect(() => {
    //   if (this.shiptome) this.shipme = true;
    // });

    console.log("this.shopper.flags.is_guest", this.shopper.flags.is_guest);
    console.log("this.addr.phone:", this.addr.phone);
    console.log("this.myPhone:", this.myPhone);
    console.log("this.shipme:", this.shipme);
  },
  methods: {
    selectPostalCode(e, p) {
      e.preventDefault();
      this.gpostalcode = p.code;
    },
    changeShipToMe(value) {
      this.shipme = value;
      this.setErrorMessage("");
    },
    clearGmapAutocomplete(e) {
      e.preventDefault();
      this.$refs.mapAutocomplete.$refs.input.value = "";
      this.formattedAddr = "";
      this.gpostalcode = "";
      this.gprovince = "";
      this.gcity = "";
      this.address_1 = "";
      this.address_2 = "";
      this.markerdrag = false;
    },
    onPlaceChanged(place) {
      if (place?.geometry) {
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.currentPlace = place;
        this.markers = [{ position: marker }];
        this.center = marker;
        this.formattedAddr = place.formatted_address;
        this.formatAddress(place);
      } else {
        return false;
      }
    },
    geoLocate() {
      console.log("geoLocate this.shopLat:", this.shopLat);
      console.log("geoLocate this.shopLong :", this.shopLong);
      if (
        typeof this.shopLat !== "undefined" &&
        typeof this.shopLong !== "undefined"
      ) {
        this.center = {
          lat: parseFloat(this.shopLat),
          lng: parseFloat(this.shopLong),
        };
        const marker = {
          lat: parseFloat(this.shopLat),
          lng: parseFloat(this.shopLong),
        };
        // set lat lng as new marker & place
        console.log("geoLocate marker:", marker);
        this.setMarkerPosition(marker);
        this.setGeocode(marker);
        this.logGetPermission = `Location set from cookies Lat:${marker.lat} Long:${marker.lng}`;
      }

      // read cookie from shopper app

      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          const marker = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          // set lat lng as new marker & place
          this.setMarkerPosition(marker);
          this.setGeocode(marker);
          this.logGetPermission = "";
        },
        (error) => {
          // this.setErrorMessage("GeolocationPositionError " + error.message);
          console.error("geoLocate unknown error");
          console.error("GeolocationPositionError " + error.message);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 1000 },
      );
    },
    async setMarkerPosition(marker) {
      await this.$gmapApiPromiseLazy().then(() => {
        this.markers = [{ position: marker }];
        this.center = marker;
        this.$refs.gmapRef.$mapPromise.then((map) => {
          console.log("setMarkerPosition map:", map);
        });
      });
    },
    async setGeocode(position, confirm) {
      await this.$gmapApiPromiseLazy().then(() => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ latLng: position }, (result, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            // accessing autocomplete reference and populating first address
            this.formatAddress(result[0], confirm);
            this.formattedAddr = result[0].formatted_address;
          }
        });
      });
    },
    async formatAddress(place) {
      console.log("formatAddress place:", place);
      // accessing autocomplete reference and populating first address
      this.currentPlace = await place;

      if (place?.geometry) {
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.markers = [{ position: marker }];
        this.center = marker;
      }

      const address = place?.address_components.reduce(
        (seed, { long_name, types }) => {
          types.forEach((t) => {
            seed[t] = long_name;
          });

          return seed;
        },
        {},
      );

      this.markerdrag = true;

      // save geocode address
      // await this.geocodeAddress(address);
      this.assignAddress(address);
      // await this.postalCodeLookUp(address);

      console.log("formatAddress address:", address);

      await this.postalCodeLookUp(address);
    },
    assignAddress(address) {
      console.log("assignAddress address", address);
      console.log("assignAddress markers", this.markers);
      console.log(
        "this.$refs.mapAutocomplete.$refs.input",
        this.$refs.mapAutocomplete.$refs.input,
      );

      // this.postalData = []

      if (address.country !== "Indonesia") {
        // this.$toast.error(this.$t("ship_only_indonesia"), {
        //   duration: 5000,
        // });
        this.address_1 = "";
        this.gprovince = "";
        this.gcity = "";
        this.gpostalcode = 0;
        this.$refs.mapautocomplete.$refs.input.value = "";
        this.$refs.mapautocomplete.$refs.input.focus();
      }

      if (!address.administrative_area_level_2) {
        this.gpostalcode = 0;
        this.gcity = "";
        this.$refs.mapAutocomplete.$refs.input.focus();
      }

      this.gpostalcode = address?.postal_code ? address?.postal_code : "";
      this.gprovince = address?.administrative_area_level_1 || "";

      this.gcity =
        address?.administrative_area_level_2
          .toUpperCase()
          .replace("KABUPATEN", "KAB.") || "";
      this.gdistrict = address?.administrative_area_level_3 || "";
      this.gvillage = address?.administrative_area_level_4 || "";

      if (
        address?.administrative_area_level_6 &&
        address?.administrative_area_level_7
      ) {
        let rt = address?.administrative_area_level_7.toUpperCase() || "";
        let rw = address?.administrative_area_level_6.toUpperCase() || "";

        if (!rt.includes("RT")) {
          rt = "RT" + rt;
        }
        if (!rw.includes("RW")) {
          rw = "RW" + rw;
        }

        this.address_2 = rt + " " + rw;
      }

      // street
      // if (address?.street_number) {
      //   address_1 += ' ' + address.street_number
      //   this.address_1 = address_1
      // }

      if (this.currentPlace?.formatted_address) {
        this.address_1 = this.currentPlace.formatted_address;
        this.$refs.mapAutocomplete.$refs.input.value =
          this.currentPlace.formatted_address;
      }
      this.formattedAddr = this.currentPlace.formatted_address;
    },
    onDragMarker() {
      console.log("onDragMarker");
      this.markerdrag = true;
    },
    updateMarker(e) {
      this.$gmapApiPromiseLazy().then(() => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ latLng: e.latLng }, (result, status) => {
          console.log("updateMarker result", result);
          if (status === google.maps.GeocoderStatus.OK) {
            // accessing autocomplete reference and populating first address
            this.formatAddress(result[0]);
          }
        });
      });
    },
    async getCurrentLocation(e) {
      // prevent modalFormAddress close
      e.preventDefault();
      this.logGetPermission = "";
      console.log("this.isMacOS:", this.isMacOS);
      console.log("this.isIos:", this.isIos);
      if (this.isIos || this.isMacOS) {
        // this.geoLocate();
        await this.getPermissionLocation();
      } else {
        await this.getPermissionLocation();
      }
    },
    async getPermissionLocation() {
      console.log("navigator?.permissions:", navigator?.permissions);
      if (navigator?.permissions) {
        await navigator?.permissions
          ?.query({
            name: "geolocation",
          })
          .then((result) => {
            this.logGetPermission = "Get location permission : " + result.state;
            if (result.state === "granted") {
              this.errorMessage = "";
              this.geoLocate();
            } else if (result.state === "prompt") {
              this.errorMessage = "";
              this.geoLocate();
            } else if (result.state === "denied") {
              this.errorMessage =
                "Beri akses lokasi untuk menggunakan lokasi saat ini";
            }
          });
      } else {
        this.geoLocate();
      }
    },
    async postalCodeLookUp(ad) {
      console.log("postalCodeLookUp:", ad);
      this.loadingPostal = true;
      let place = "";

      if (ad?.administrative_area_level_3) {
        place += ad.administrative_area_level_3.replace("Kecamatan", "");
      }

      if (ad?.administrative_area_level_4) {
        place += "," + ad.administrative_area_level_4;
      }

      if (place !== "") {
        console.log("postalCodeLookUp place:", place);
        const { data } = await useFetch(
          `https://flik-postal.vercel.app/search/?q=${place}`,
        );

        const postalData = data.value?.data || [];

        if (postalData.length > 0) {
          this.postalCodes = postalData;
          if (process.client) {
            const gpostalCodeElm = document.getElementById("gpostalcode");
            gpostalCodeElm.scrollIntoView({
              behavior: "smooth",
            });
          }
        } else {
          console.warn("postal code suggestion not available");
        }
      }
      this.loadingPostal = false;
    },
    setErrorMessage(msg) {
      this.errorMessage = msg;
      const elmFormAddress = document.getElementById("modalFormAddress");
      elmFormAddress.scrollIntoView({
        behavior: "smooth",
      });
    },
    async submitAddress() {
      if (
        !this.shipme &&
        (this.recipient_name === "" || this.recipient_phone === "")
      ) {
        this.setErrorMessage("Nama & Nomor HP Penerima wajib diisi!");
        return;
      } else if (!this.address_1) {
        this.setErrorMessage("Alamat lengkap wajib diisi!");
        return;
      } else if (!this.gpostalcode) {
        this.setErrorMessage("Kode pos wajib diisi!");
        return;
      }

      const shopper = useShopperStore();

      console.log("shopper.myEmail:", shopper.myEmail);
      if (shopper.myEmail === "" && !shopper.flags.is_guest) {
        return await navigateTo({ path: "/profile" });
      }

      const email = this.myEmail;
      console.log("email:", email);

      let name = this.firstName + " " + this.lastName;
      console.log("name:", name);
      let phone = this.myPhone;
      console.log("phone:", phone);
      let address_label = name + " | " + phone;

      console.log("this.myPhone:", this.myPhone);
      console.log("phone:", phone);

      console.log("this.recipient_name:", this.recipient_name);
      if (this.recipient_name) name = this.recipient_name;
      console.log("this.recipient_phone:", this.recipient_phone);
      if (this.recipient_phone) phone = this.recipient_phone;

      if (!this.shipme && name !== "" && phone !== "") {
        address_label = name + " | " + phone;
      }
      console.log("this.shipme:", this.shipme);

      if (
        !this.shipme &&
        name !== "" &&
        phone !== "" &&
        shopper.flags.is_guest
      ) {
        address_label = name + " | " + this.myEmail;
      }

      console.log("address_label:", address_label);

      const payload = {
        address_id: this.addr ? this.addr.address_id : "",
        address_label,
        email,
        name,
        phone,
        status: "active",
        type: "consignee",
        address_1: this.address_1,
        address_2: this.address_2,
        address_3: this.gdistrict.length ? this.gdistrict : this.addr.address_3,
        address_4: this.gvillage.length ? this.gvillage : this.addr.address_4,
        city: this.gcity,
        state_province: this.gprovince,
        country_code: "ID",
        postal_code: this.gpostalcode,
        address_note: "",
        lat: this.markers[0].position.lat,
        lng: this.markers[0].position.lng,
        is_default: true,
      };
      this.setErrorMessage("");
      // DEBUG
      console.log("submitAddress payload:", payload);
      // return;
      // // eslint-disable-next-line no-unreachable
      const sd = await useAddressStore().saveAddress(payload);
      if (sd) {
        await shopper.getShopper();
        useUiStore().modalFormAddress = false;
        useUiStore().modalAddress = false;
        const shipping = useShippingStore();
        shipping.setShippingLoading(true);
        await shipping.checkMultiCourierRates();
        shipping.setShippingLoading(false);
        const promo = usePromoStore();
        promo.calcTotalShippingDiscount();
        promo.calcTotalReward();
        promo.calcTotalPromo();
        useNotification().notify({
          text: "Alamat berhasil ditambahkan",
          type: "success",
        });
        amplitudeTrackBtnClicked("Submit Address Button", "", "");
      } else {
        this.setErrorMessage("Gagal menyimpan alamat");
      }
    },
  },
};
</script>

<template>
  <form
    id="formAddress"
    class="relative"
    @submit.prevent="submitAddress"
    @keydown.enter.prevent
  >
    <div
      v-if="errorMessage"
      class="p-3 mb-4 bg-red-100 border border-red-300 rounded-lg"
    >
      <span class="ml-2 text-flk-red error">{{ errorMessage }}</span>
    </div>

    <div class="h-6 mb-4 text-base font-medium leading-normal text-gray-900">
      Detail alamat
    </div>

    <div v-if="!shopper.flags.is_guest" class="flex items-center mb-5">
      <input
        id="shipToMe"
        v-model="shipme"
        :checked="shipme"
        class="cursor-pointer flk-check"
        type="checkbox"
        @change="changeShipToMe(shipme)"
      />
      <label class="ml-2 text-gray-80" for="shipToMe">
        Kirim ke

        <span v-if="!shipme" class="font-bold">
          {{ firstName }} | {{ myEmail }}
        </span>
        <span v-else class="font-bold">{{ firstName }} | {{ myPhone }}</span>
      </label>
    </div>

    <div v-if="!shipme" class="flex justify-between mb-5 inputGroup gap-2">
      <div class="w-[60%]">
        <label for="province">Nama penerima</label>
        <input
          id="recipient_name"
          ref="refRecipientName"
          v-model.trim="recipient_name"
          type="text"
          class="flk-input"
          aria-describedby="Nama penerima"
          placeholder="Masukkan nama penerima"
        />
      </div>
      <div lass="w-[40%]">
        <label for="gcity">Nomor HP penerima</label>
        <input
          id="recipient_phone"
          v-model.trim="recipient_phone"
          type="text"
          class="flk-input"
          aria-describedby="Nomor HP penerima"
          placeholder="Masukkan nomor HP penerima"
        />
      </div>
    </div>

    <label for="mapAutocomplete">Alamat pengiriman</label>
    <div class="relative wrapAutocomplete">
      <IconsSearch class="absolute top-[13px] left-2" />
      <GMapAutocomplete
        id="mapAutocomplete"
        ref="mapAutocomplete"
        class="mb-3 flk-input pl-7"
        placeholder="Cari alamat atau lokasi"
        :options="{
          fields: ['geometry', 'address_components', 'formatted_address'],
          strictBounds: true,
          types: ['establishment'],
          componentRestrictions: { country: 'id' },
        }"
        @place_changed="onPlaceChanged"
        @keydown.enter.prevent
      >
      </GMapAutocomplete>
      <button
        v-if="currentPlace?.formatted_address"
        class="absolute top-[8px] right-2 border rounded-full text-gray-700 hover:text-white bg-gray-200 hover:bg-gray-500 p-[4px] cursor-pointer"
        aria-label="Clear"
        @click="clearGmapAutocomplete"
      >
        <IconsClose />
      </button>
    </div>

    <GMapMap
      ref="gmapRef"
      :center="center"
      :zoom="15"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
      }"
      style="width: 100%; height: 232px; margin: auto"
    >
      <GMapMarker
        v-for="(marker, index) in markers"
        :key="index"
        :position="marker.position"
        :clickable="true"
        :draggable="true"
        @click="center = marker.position"
        @dragend="updateMarker"
        @drag="onDragMarker"
      >
      </GMapMarker>
    </GMapMap>

    <div class="wrapGetLocation">
      <div
        v-if="
          APP_DEBUG &&
          typeof shopLat !== 'undefined' &&
          typeof shopLong !== 'undefined'
        "
        class="text-orange-600 py-2 mt-3"
      >
        <p>{{ logGetPermission }}</p>
      </div>

      <button
        class="w-full mx-auto mt-3 mb-4 cursor-pointer btn-flk-hollow active:bg-sky-400"
        @click="(e) => getCurrentLocation(e)"
      >
        <div class="text-sm font-medium leading-tight text-sky-950">
          Gunakan lokasi saat ini
        </div>
        <LazyIconsGetLocation />
      </button>
    </div>

    <div v-if="hasLength(address_1)" id="groupAddr">
      <div class="mb-4 inputGroup">
        <label for="address_1">Alamat lengkap</label>
        <textarea id="address_1" v-model="address_1"> </textarea>
      </div>

      <div class="mb-4 inputGroup">
        <label for="address_1">Alamat detail</label>

        <input
          id="address_1"
          v-model="address_2"
          type="text"
          placeholder="Detail alamat pengiriman"
        />
      </div>

      <div class="flex justify-between mb-4 inputGroup gap-2">
        <div class="w-1/2">
          <label for="province">Provinsi</label>
          <input
            v-model="gprovince"
            type="text"
            readonly
            placeholder="Provinsi"
          />
        </div>
        <div lass="w-1/2">
          <label for="gcity">Kota</label>
          <input v-model="gcity" type="text" readonly placeholder="Kota" />
        </div>
      </div>
      <div class="mb-4 inputGroup">
        <label for="address_1">Kode Pos</label>

        <input
          id="gpostalcode"
          v-model="gpostalcode"
          type="text"
          placeholder="Masukkan atau pilih kode pos"
        />
      </div>

      <div class="flex items-center justify-start inputGroup">
        <button
          v-for="(p, k) in postalCodes"
          :key="k"
          class="text-xs text-gray-600 px-2.5 py-1.5 border border-gray-400 rounded-lg cursor-pointer active:bg-blue-600 active:text-white"
          @click="(e) => selectPostalCode(e, p)"
        >
          {{ p.code }}
        </button>
      </div>
    </div>

    <button
      id="btnSubmitAddress"
      type="submit"
      class="w-full mx-auto mt-5 cursor-pointer btn-flk-dark"
    >
      <div class="text-sm font-medium leading-tight text-white">Simpan</div>
    </button>
  </form>
</template>

<style scoped>
label {
  @apply text-neutral-900 text-sm font-medium leading-tight;
}
textarea,
input[type="text"] {
  @apply read-only:text-neutral-400;
  @apply read-only:bg-gray-200;
  @apply flk-input text-sm;
}
</style>
